var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
export function pluralize(count, one, two, five, placeholder) {
    if (five === void 0) { five = two; }
    if (placeholder === void 0) { placeholder = '%%'; }
    var number = count % 100;
    var declension = one;
    if (number > 4 && number <= 20) {
        declension = five;
    }
    else {
        number = number % 10;
        if (number >= 5 || number === 0) {
            declension = five;
        }
        else if (number >= 2 && number <= 4) {
            declension = two;
        }
    }
    if (declension.match(placeholder)) {
        return declension.replace(placeholder, count.toString());
    }
    return declension;
}
export function format(number) {
    number = number.toString();
    if (number.match(/\d{4}/)) {
        return format(number.replace(/(\d)(\d{3})\b/, '$1 $2'));
    }
    return number;
}
export function addLiveEventListener(element, events, elementSelector, callback) {
    events.split(' ').forEach(function (eventType) {
        element.addEventListener(eventType, function (event) {
            if (event.target instanceof HTMLElement && event.target.matches(elementSelector)) {
                return callback.call(event.target, event);
            }
        });
    });
}
/**
 * This allows us to dispatch browser events in old IE and newer browsers
 */
export function triggerEvent(elem, eventName, data) {
    if (data === void 0) { data = null; }
    var event = data ? new CustomEvent(eventName, { detail: data }) : new CustomEvent(eventName);
    elem.dispatchEvent(event);
    return event;
}
export function triggerProductsLoaded() {
    triggerEvent(document, 'products_loaded');
}
export function join(arr, separator) {
    if (separator === void 0) { separator = ' '; }
    return arr.filter(function (n) { return n; }).join(separator);
}
export function escapeRegex(string) {
    return string.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
export var isMobile = window.matchMedia('(pointer: coarse)').matches && window.innerWidth <= 768 && 'ontouchstart' in window;
export function getFetchOptions(body, credentials, method) {
    if (credentials === void 0) { credentials = false; }
    if (method === void 0) { method = 'POST'; }
    return {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: credentials ? 'same-origin' : 'omit',
    };
}
export function fixInjectedInlineScripts(element) {
    var e_1, _a;
    try {
        for (var _b = __values(element.querySelectorAll('script')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var script = _c.value;
            var newScript = document.createElement('script');
            newScript.text = script.innerHTML;
            script.parentElement.append(newScript);
            script.remove();
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export function isHidden(el) {
    return (el.offsetParent === null);
}
export function onModalOpen(modalId, callback) {
    document.addEventListener('modal:open', function (event) {
        var modal = event.detail.modal;
        if (!modal || modal.id !== modalId) {
            return;
        }
        callback(modal);
    });
}
export function emulateReadonly(element) {
    element.style.caretColor = 'transparent';
    element.addEventListener('keydown', onkeypress);
    element.addEventListener('paste', onkeypress);
    function onkeypress(event) {
        if (event.key.length === 1) {
            event.preventDefault();
        }
    }
}
export function clearDataset(el) {
    Object.keys(el.dataset).forEach(function (key) { return delete el.dataset[key]; });
}
export function extractData(el) {
    var dataEl = el.querySelector('script[type="application/json"]');
    if (!dataEl) {
        return null;
    }
    return JSON.parse(dataEl.textContent);
}
