var globalPreloader;
var currentPreloader;
var preloaderActive = false;
export function makePreloader(el) {
    if (preloaderActive) {
        return {
            close: hidePreloader
        };
    }
    doShow(el);
    return {
        close: function () { return doHide(el); },
    };
}
export function hidePreloader(delay, el) {
    var preloader = (el ? el : currentPreloader);
    if (!preloader) {
        return;
    }
    if (delay) {
        setTimeout(hidePreloader.bind(this, false, preloader), 150);
        return;
    }
    doHide(preloader);
}
function doHide(el) {
    el.classList.remove('active');
    preloaderActive = false;
    if ('ontransitionend' in el) {
        el.addEventListener('transitionend', disable.bind(el), { once: true });
    }
    setTimeout(function () { return el.classList.remove('enabled'); }, 1000);
}
export function showPreloader(el) {
    if (preloaderActive) {
        return;
    }
    currentPreloader = el ? el : initGlobalPreloader();
    doShow(currentPreloader);
}
function doShow(el) {
    if (!el.classList.contains('preloader')) {
        el.classList.add('preloader');
        el.classList.add('inline');
    }
    el.removeEventListener('transitionend', disable);
    el.classList.add('enabled');
    preloaderActive = true;
    setTimeout(function () { return el.classList.add('active'); });
}
function initGlobalPreloader() {
    if (!globalPreloader) {
        globalPreloader = document.createElement('div');
        globalPreloader.classList.add('preloader');
        document.body.appendChild(globalPreloader);
    }
    return globalPreloader;
}
function disable() {
    this.classList.remove('enabled');
    this.removeEventListener('transitionend', disable);
}
window['showPreloader'] = showPreloader;
