var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import 'micromodal';
import 'core-js/features/object/assign';
import { hidePreloader, showPreloader } from "./preloader";
import { fixInjectedInlineScripts, triggerEvent } from '../utils';
import MicroModal from "micromodal";
import ajax from "./ajax";
import $ from '../vendor/dom7';
var routed = ['callback', 'find_cheaper', 'preorder', 'subscribe_brand', 'gift_card_activation'];
export var microModalDefaults = {
    openTrigger: 'data-custom-open',
    disableScroll: false,
    disableFocus: true,
    awaitCloseAnimation: true,
    onShow: function (modal) {
        // focus elements
        var focusable = Array.from(modal.querySelectorAll([
            'input:not([disabled]):not([type="hidden"]):not([aria-hidden]):not([data-micromodal-nofocus]):not([data-micromodal-close])',
            'select:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus])',
            'textarea:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus])',
            'button:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus]):not([data-micromodal-close])',
        ].join(', ')));
        if (focusable.length) {
            var focusableEmpty = focusable.filter(function (element) { return element.value === ''; });
            if (focusableEmpty.length) {
                focusableEmpty[0].focus();
            }
            else {
                focusable[0].focus();
            }
        }
        triggerEvent(document, 'modal:open', { modal: modal });
        fixModalClosing(modal);
    },
    onClose: function (modal) {
        var url = modal.dataset.redirect;
        triggerEvent(document, 'modal:close', { modal: modal });
        if (!url) {
            return;
        }
        showPreloader();
        document.location = url;
        if (url[0] === '#') {
            document.location.reload();
        }
    }
};
/**
 * @param {HTMLDivElement} modal
 */
function fixModalClosing(modal) {
    var overlay = modal.querySelector('.modal__overlay');
    var inner = overlay.firstElementChild;
    var innerClick = false;
    inner.addEventListener('pointerdown', function () {
        innerClick = true;
    });
    inner.addEventListener('pointerup', function (event) {
        innerClick = false;
        event.stopPropagation();
    });
    overlay.addEventListener('pointerup', function () {
        if (innerClick) {
            innerClick = false;
            return;
        }
        MicroModal.close(modal.id);
    });
}
export default function initModal() {
    document.addEventListener('DOMContentLoaded', function () {
        MicroModal.init(microModalDefaults);
        // ссылки, вызывающие модальные окна
        $(document.body).on('click', '.win', function (e) {
            var _a;
            var button = this;
            var $this = $(button);
            if (button.dataset.modalId) {
                if (document.getElementById(button.dataset.modalId)) {
                    MicroModal.show(button.dataset.modalId, microModalDefaults);
                    return;
                }
            }
            var act = $this.data('act');
            e.preventDefault();
            showPreloader();
            ajax("/ajax/win/".concat(act), {
                id: (_a = $this.data('id')) !== null && _a !== void 0 ? _a : 0,
                type: $this.data('type'),
            })
                .then(function (response) {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("/json") !== -1) {
                    return response.json();
                }
                return response.text();
            })
                .then(function (data) {
                if (typeof data === 'string') {
                    showModal(data);
                }
                else {
                    initAssets(data)
                        .then(function (element) {
                        if (data.html) {
                            showModal(element !== null && element !== void 0 ? element : data.html);
                        }
                        if (data.callback) {
                            if (typeof global[data.callback] === 'function') {
                                return global[data.callback](data);
                            }
                        }
                    });
                }
            });
            function showModal(html) {
                var id = showModalFromHtml(html);
                if (id) {
                    button.dataset.modalId = id;
                }
                hidePreloader();
            }
        });
        // отправка форм аяксом в модальных окнах
        $(document.body).on('submit', 'form', function (e) {
            var form = this;
            var $form = $(this);
            var $ajaxSubmit = $form.find('.ajax_submit');
            var act = $ajaxSubmit.data('act');
            var url;
            if (!($ajaxSubmit.length && act)) {
                return;
            }
            url = '/ajax/win_act/' + act + (routed.includes(act) ? '' : '.php');
            e.preventDefault();
            showPreloader();
            ajax(url, $form[0])
                .then(function (response) { return response.json(); })
                .then(function (json) {
                if (json && json.ok) {
                    triggerEvent(form, 'ajax-form-success');
                    if (json.html) {
                        showModalFromHtml(json.html);
                    }
                    else if (json.win) {
                        if ($('#' + json.win).length) {
                            MicroModal.show(json.win, microModalDefaults);
                        }
                        else {
                            $('<a class="win" data-act="' + json.win + '"></a>').appendTo('.window').click().remove();
                        }
                    }
                    else {
                        // @ts-ignore
                        document.location.reload(true);
                        return;
                    }
                }
                else if (json && typeof json.error === 'object') {
                    highlightFormErrors(form, json.error);
                }
            })
                .finally(function () { return hidePreloader(); });
        });
        // submit forms on enter, when forms are not submittable
        $(document.body).on('keyup', '.enter', function (e) {
            if (e.keyCode === 13) {
                $(this).parents('form').find('.ajax_submit').click();
            }
        });
        // ajax submittable forms
        $(document.body).on('click', '.ajax_submit', function () {
            var $form = $(this).parents('form');
            if (this.type !== 'submit') {
                var $submit = $form.find('[type=submit]');
                if (!$submit.length) {
                    var $input = $('<input type="submit">');
                    $input.hide();
                    $submit = $input.appendTo($form);
                }
                $submit.click();
            }
        });
        $('.win[data-open="1"]').click();
        var autoOpenModal = $('.modal[data-auto-open]');
        if (autoOpenModal.length) {
            MicroModal.show(autoOpenModal.attr('id'), microModalDefaults);
        }
        // handle hash navigation
        checkHashDeeplink(document.location.hash.replace(/^#/, ''));
    });
}
var loading = 0;
function initAssets(data) {
    if (!data.assets) {
        return new Promise(function (resolve) { return resolve(null); });
    }
    var existingAssets = __spreadArray(__spreadArray([], __read(Array.from(document.scripts).map(function (el) { return el.src; })), false), __read(Array.from(document.querySelectorAll('link[rel="stylesheet"]')).map(function (el) { return el.href; })), false);
    var virtual = document.createElement('div');
    virtual.innerHTML = data.html + data.assets;
    return new Promise(function (resolve) {
        var e_1, _a, e_2, _b, e_3, _c;
        var resolveCallback = function () {
            resolve(virtual.firstElementChild);
        };
        try {
            for (var _d = __values(virtual.querySelectorAll('script[src]')), _e = _d.next(); !_e.done; _e = _d.next()) {
                var element = _e.value;
                if (!existingAssets.includes(element.src)) {
                    enqueueItem(element, resolveCallback);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var _f = __values(virtual.querySelectorAll('link')), _g = _f.next(); !_g.done; _g = _f.next()) {
                var element = _g.value;
                if (!existingAssets.includes(element.href)) {
                    enqueueItem(element, resolveCallback);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
        if (data.html) {
            try {
                for (var _h = __values(virtual.querySelectorAll('script,style')), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var element = _j.value;
                    virtual.firstElementChild.append(element);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (!loading) {
            resolveCallback();
        }
    });
}
function enqueueItem(element, resolve) {
    loading++;
    var el = document.createElement(element.tagName);
    document.body.append(el);
    el.onload = onload;
    if ('src' in el && 'src' in element) {
        if (element.type) {
            el.type = element.type;
        }
        else if ('noModule' in el) {
            el.noModule = element.attributes['nomodule'] !== undefined;
            loading--;
        }
        el.src = element.src;
    }
    else if ('href' in el && 'href' in element) {
        el.rel = element.rel;
        el.href = element.href;
    }
    element.remove();
    function onload() {
        return !--loading && resolve();
    }
}
export function highlightFormErrors(form, errors) {
    if (errors === void 0) { errors = {}; }
    var $form = $(form);
    resetFormErrors(form);
    for (var field in errors) {
        $form.find("input[name=\"".concat(field, "\"],textarea[name=\"").concat(field, "\"],select[name=\"").concat(field, "\"]"))
            .parents('.form-group').addClass('has-error')
            .find('.form-fb--invalid').text(errors[field]);
    }
}
export function resetFormErrors(form) {
    $(form).find('.has-error').removeClass('has-error');
}
export function closeExistingModal() {
    var modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    var closeButton = modalRoot.querySelector('[data-micromodal-close], .modal__close');
    if (closeButton) {
        closeButton.click();
    }
}
export function showModalFromHtml(modal) {
    var modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    closeExistingModal();
    if (typeof modal === 'string') {
        modalRoot.innerHTML = modal;
    }
    else {
        modalRoot.innerHTML = '';
        modalRoot.append(modal);
    }
    fixInjectedInlineScripts(modalRoot);
    var id = modalRoot.querySelector('.window>div').id;
    MicroModal.show(id, microModalDefaults);
    return id;
}
function checkHashDeeplink(hash) {
    if (!hash) {
        return;
    }
    var modal = document.querySelector(".win[data-act=\"".concat(hash, "\"]"));
    if (!modal) {
        return;
    }
    modal.click();
}
